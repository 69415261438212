import { type FC, type ReactNode, useEffect, useState } from "react";
import { AuthContext } from "./auth-context";
import { Issuer } from "src/utils/auth";
import { type RouterOutputs, trpc } from "src/utils/trpc";
import { identifyUser } from "src/utils/amplitude";

const STORAGE_TOKEN_KEY = "accessToken";
const STORAGE_REFRESH_TOKEN_KEY = "refreshToken";
const STORAGE_TENANT_KEY = "tenantId";

interface AuthProviderProps {
  children: ReactNode;
}

// App.tsx is loading this for now.
const storedAccessToken = window.localStorage.getItem(STORAGE_TOKEN_KEY);
const storedRefreshToken = window.localStorage.getItem(
  STORAGE_REFRESH_TOKEN_KEY,
);
// const tenantId = window.localStorage.getItem(STORAGE_TENANT_KEY);

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [user, setUser] = useState<RouterOutputs["auth"]["me"]>();
  const [token, setToken] = useState<string>(storedAccessToken ?? "");
  const [refreshToken, setRefreshToken] = useState<string>(
    storedRefreshToken ?? "",
  );

  const { mutateAsync: getSignOut } = trpc.auth.signout.useMutation();

  /**
   * With WorkOS, we need to sign out from the session itself, this will require making a call to the
   * server to get the signoutURL and redirect the user to that URL. with the return url to the login page.
   *
   */
  const signOut = async () => {
    const { logoutUrl } = await getSignOut();

    // TODO: fix this after we solve App.tsx state
    localStorage.removeItem(STORAGE_TOKEN_KEY);
    localStorage.removeItem(STORAGE_TENANT_KEY);
    setUser(undefined);
    window.location.replace(logoutUrl);
  };

  const { mutateAsync: getSignIn } = trpc.auth.signin.useMutation();

  const signIn = async (): Promise<void> => {
    const { redirectUrl } = await getSignIn();
    window.location.replace(redirectUrl);
  };

  const {
    data: me,
    error: meError,
    isInitialLoading,
  } = trpc.auth.me.useQuery(undefined, {
    enabled: token.length > 0,
  });
  useEffect(() => {
    if (me) {
      setUser(me);
      window.localStorage.setItem(STORAGE_TENANT_KEY, me.tenant.id ?? "");
    }
  }, [me]);

  useEffect(() => {
    if (meError) {
      console.error("auth-provider: Failed to fetch current user", meError);
    }
  }, [meError]);

  const { mutateAsync: getSignInCallback } = trpc.auth.callback.useMutation();

  const signInCallback = async (code: string) => {
    const tokenAndProfile = await getSignInCallback({ code });

    if (tokenAndProfile && tokenAndProfile.accessToken) {
      setToken(tokenAndProfile.accessToken);
      setRefreshToken(tokenAndProfile.refreshToken);
      window.localStorage.setItem(
        STORAGE_TOKEN_KEY,
        tokenAndProfile.accessToken,
      );
      window.localStorage.setItem(
        STORAGE_REFRESH_TOKEN_KEY,
        tokenAndProfile.refreshToken,
      );
    }

    return tokenAndProfile;
  };

  const switchTenant = async (accessToken: string, refreshToken: string) => {
    setToken(accessToken);
    setRefreshToken(refreshToken);
    window.localStorage.setItem(STORAGE_TOKEN_KEY, accessToken);
    window.localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, refreshToken);
    window.sessionStorage.clear();
    window.location.href = "/";
  };

  // Create the value for the auth context.
  const auth = {
    isInitialized: !token || !isInitialLoading,
    isAuthenticated: Boolean(user),
    user: user,
    token,
    refreshToken,
    issuer: Issuer.JWT,
    signIn,
    signInCallback,
    signOut,
    switchTenant,
  };

  // Keep Amplitude up-to-date on the user's email.
  useEffect(() => {
    if (auth.user?.email) {
      identifyUser(auth.user.email);
    }
  }, [auth.user?.email]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
