import { index, jsonb, pgEnum, varchar } from "drizzle-orm/pg-core";
import type { WorkOSUserId, UserId } from "../../agid";
import { createBaseWithId, ownership } from "./base";
import { agConfig } from "./schema";

export const UserStatus = [
  "CREATING",
  "ACTIVE",
  "INACTIVE",
  "SUSPENDED",
] as const;
export type UserStatus = (typeof UserStatus)[number];
export const pgUserStatus = pgEnum("user_status", UserStatus);

// For users, since they are now mapping with WorkOS,  we don't want to give them a tenant ID.
const { tenantId: _, ...baseWithoutTenant } = createBaseWithId<UserId>();

export const User = agConfig.table(
  "user",
  {
    ...baseWithoutTenant,
    ...ownership,
    // WorkOS User ID
    workosId: varchar("user_id").$type<WorkOSUserId>().unique().notNull(),
    userStatus: pgUserStatus("user_status").default("CREATING").notNull(),
    firstName: varchar("first_name"),
    lastName: varchar("last_name"),
    email: varchar("email").unique().notNull(),
    shortId: varchar("short_id").unique().notNull(),
    config: jsonb("config").notNull(),
  },
  (table) => {
    return {
      workosId: index("ix_user_workos_id").on(table.workosId),
    };
  },
);
