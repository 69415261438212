import { z } from "zod";
import { TenantShortId, WorkOSOrgId, WorkOSUserId } from "allgood-schema";
import { RoleSlug, UserType } from "./workos.schema";

/**
 * Type for permission key
 */
export const PermissionKey = z.enum([
  "WORKSHEETS_VIEW",
  "WORKSHEETS_TEMPLATES_USE",
  "WORKSHEETS_MANAGE",
  "LAB_SKILLS_VIEW",
  "LAB_SKILLS_MANAGE",
  "LAB_TEAMS_VIEW",
  "LAB_TEAMS_MANAGE",
  "LAB_TOOLS_VIEW",
  "LAB_TOOLS_MANAGE",
  "INTEGRATIONS_MANAGE",
  "SYSTEM_MANAGE",
  "USERS_MANAGE",
]);
export type PermissionKey = z.infer<typeof PermissionKey>;

/**
 * Permission schema defining a single permission with a slug and display name
 */
export const PermissionSchema = z.object({
  slug: z.string(),
  name: z.string(),
});
export type Permission = z.infer<typeof PermissionSchema>;

/**
 * Collection of all system permissions
 */
export const PermissionsSchema = z.record(PermissionKey, PermissionSchema);
export type Permissions = z.infer<typeof PermissionsSchema>;

/**
 * Define all available permissions
 */

export const PERMISSIONS: Permissions = {
  WORKSHEETS_VIEW: {
    slug: "worksheets:view",
    name: "View existing worksheets",
  },
  WORKSHEETS_TEMPLATES_USE: {
    slug: "worksheets:templates:use",
    name: "Use worksheet templates",
  },
  WORKSHEETS_MANAGE: {
    slug: "worksheets:manage",
    name: "Create and edit worksheets",
  },
  LAB_SKILLS_VIEW: {
    slug: "lab:skills:view",
    name: "View skills in the lab",
  },
  LAB_SKILLS_MANAGE: {
    slug: "lab:skills:manage",
    name: "Create and edit skills",
  },
  LAB_TEAMS_VIEW: {
    slug: "lab:teams:view",
    name: "View existing teams",
  },
  LAB_TEAMS_MANAGE: {
    slug: "lab:teams:manage",
    name: "Create and edit teams",
  },
  LAB_TOOLS_VIEW: {
    slug: "lab:tools:view",
    name: "View tools configurations",
  },
  LAB_TOOLS_MANAGE: {
    slug: "lab:tools:manage",
    name: "Create and edit custom tools",
  },
  INTEGRATIONS_MANAGE: {
    slug: "integrations:manage",
    name: "Set up and manage integrations",
  },
  SYSTEM_MANAGE: {
    slug: "system:manage",
    name: "Manage system settings, users, and view logs",
  },
  USERS_MANAGE: {
    slug: "users:manage",
    name: "Manage users, invite, remove etc.",
  },
};

/**
 * Check if the given permissions array includes a specific permission
 */
export function hasPermission(
  permissions: string[],
  permissionKey: PermissionKey,
): boolean {
  const permission = PERMISSIONS[permissionKey];
  if (!permission) {
    throw new Error(`Permission not defined: ${permission}`);
  }
  return permissions.includes(permission.slug);
}

/**
 * This is the token coming from WorkOS with the following template
 *
 * {
 *   "id": {{user.id}},
 *   "firstName": {{user.first_name}},
 *   "lastName": {{user.last_name}},
 *   "email": {{user.email}},
 *   "emailVerified": {{user.email_verified}},
 *   "profilePictureUrl" : {{ user.profile_picture_url}},
 *   "userId": {{ user.external_id}},
 *   "userType": {{user.metadata.userType || 'USER'}},
 *   "tenantShortId": {{user.metadata.tenantShortId}},
 * }
 *
 */
export const TokenPayload = z.object({
  id: WorkOSUserId, // workos user id
  org_id: WorkOSOrgId, // workos org id,
  tenantShortId: TenantShortId,
  firstName: z.string().default(""),
  lastName: z.string().default(""),
  email: z.string(),
  emailVerified: z.boolean(),
  profilePictureUrl: z.string(),
  exp: z.number(),
  iat: z.number(),
  sid: z.string(), // session id
  sub: z.string(), // should be same as Id
  userType: UserType.default("USER"), // SUPERUSER, USER
  role: RoleSlug,
  permissions: z.array(z.string()),
});

export type TokenPayload = z.input<typeof TokenPayload>;
