import {
  hasPermission,
  type PermissionKey,
} from "allgood-api/src/services/auth.schema";
import { useAuth } from "./use-auth";

type PermissionDirectCheck = {
  key: PermissionKey;
};

type NotCheck = {
  _op: "not";
  key: PermissionKey;
};

type PermissionsOrCheck = {
  _op: "or";
  keys: PermissionCheck[];
};

type PermissionsAndCheck = {
  _op: "and";
  keys: PermissionCheck[];
};

type PermissionCheck =
  | PermissionDirectCheck
  | NotCheck
  | PermissionsOrCheck
  | PermissionsAndCheck;

export const useHasPermission = (permissionCheck: PermissionCheck): boolean => {
  const { user } = useAuth();

  if (!user) {
    return false;
  }

  const evaluate = (check: PermissionCheck): boolean => {
    if ("key" in check && !("_op" in check)) {
      return hasPermission(user.permissions, check.key);
    }

    if (check._op === "not") {
      return !hasPermission(user.permissions, check.key);
    }

    if (check._op === "and") {
      return check.keys.every(evaluate);
    }

    if (check._op === "or") {
      return check.keys.some(evaluate);
    }

    return false;
  };

  return evaluate(permissionCheck);
};
