import {
  TenantId,
  TenantShortId,
  WorkOSOrgId,
  WorkOSOrgMembershipId,
  WorkOSUserId,
} from "allgood-schema";
import { z } from "zod";

export const RoleSlug = z.enum(["admin", "poweruser", "standard", "basic"]);
export type RoleSlug = z.output<typeof RoleSlug>;

export const UserType = z.enum(["USER", "SUPERUSER"]);
export type UserType = z.output<typeof UserType>;

export const WorkOSOrg = z.object({
  id: WorkOSOrgId,
  name: z.string(),
  externalId: TenantId.nullable(),
  metadata: z.object({
    tenantShortId: TenantShortId,
    description: z.string(),
  }),
});
export type WorkOSOrg = z.output<typeof WorkOSOrg>;

export const WorkOSUser = z.object({
  id: WorkOSUserId,
  email: z.string().email(),
  emailVerified: z.boolean(),
  profilePictureUrl: z.string().nullable(),
  firstName: z.string().default(""),
  lastName: z.string().default(""),
  metadata: z.object({
    userType: UserType.default("USER"),
  }),
});
export type WorkOSUser = z.output<typeof WorkOSUser>;

export const WorkOSOrgMembership = z.object({
  id: WorkOSOrgMembershipId,
  organizationId: WorkOSOrgId,
  status: z.enum(["active", "inactive", "pending"]),
  userId: WorkOSUserId,
  role: z.object({
    slug: RoleSlug,
  }),
});
export type WorkOSOrgMembership = z.output<typeof WorkOSOrgMembership>;
