import type { FC, ReactNode } from "react";

import { withAuthGuard } from "@/hocs/with-auth-guard";
import { HorizontalLayout } from "@/layouts/dashboard/layout/horizontal-layout/horizontal-layout";
import { paths } from "@/paths";
import type { NavItemConfig } from "@/types/nav";
import { useAuth } from "@/hooks/use-auth";
import { checkIsAllGoodTenant } from "@/utils/auth";
import { useHasPermission } from "src/hooks/use-check-permissions";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard(({ children }) => {
  const auth = useAuth();
  const isAllGoodTenant = checkIsAllGoodTenant(auth.user);
  const hasWorksheetAccess = useHasPermission({
    _op: "or",
    keys: [
      {
        key: "WORKSHEETS_MANAGE",
      },
      {
        key: "WORKSHEETS_TEMPLATES_USE",
      },
      {
        key: "WORKSHEETS_VIEW",
      },
    ],
  });

  const hasLabAccess = useHasPermission({
    _op: "or",
    keys: [
      {
        key: "LAB_SKILLS_MANAGE",
      },
      {
        key: "LAB_SKILLS_VIEW",
      },
      {
        key: "LAB_TEAMS_MANAGE",
      },
      {
        key: "LAB_TEAMS_VIEW",
      },
      {
        key: "LAB_TOOLS_MANAGE",
      },
      {
        key: "LAB_TOOLS_VIEW",
      },
    ],
  });

  const hasSettingsAccess = useHasPermission({
    _op: "or",
    keys: [
      {
        key: "INTEGRATIONS_MANAGE",
      },
      {
        key: "SYSTEM_MANAGE",
      },
      {
        key: "USERS_MANAGE",
      },
    ],
  });

  const items: NavItemConfig[] = [
    {
      key: "dashboard",
      title: "",
      items: [
        {
          key: "home",
          title: "HOME",
          href: paths.index,
          matcher: {
            type: "equals" as const,
            href: paths.index,
          },
        },
        hasWorksheetAccess && {
          key: "worksheet",
          title: "WORKSHEET",
          href: paths.worksheets.index,
          matcher: {
            type: "equals" as const,
            href: paths.worksheets.index,
          },
        },
        hasLabAccess && {
          key: "lab",
          title: "LAB",
          href: paths.agents.index,
          matcher: {
            type: "startsWith" as const,
            href: "/lab",
          },
        },
        isAllGoodTenant && {
          key: "discovery",
          title: "DISCOVER",
          href: paths.discover.index,
          matcher: {
            type: "equals" as const,
            href: paths.discover.index,
          },
        },
        hasSettingsAccess && {
          key: "settings",
          title: "",
          href: paths.settings.account,
          icon: "settings",
          matcher: {
            type: "startsWith" as const,
            href: paths.settings.index,
          },
          tooltip: "Settings",
        },
        {
          key: "admin",
          title: "",
          href: paths.admin.overview,
          icon: "admin",
          matcher: {
            type: "startsWith" as const,
            href: paths.admin.index,
          },
          allowedTypes: ["SUPERUSER"] as ("SUPERUSER" | "USER")[],
          tooltip: "Admin",
        },
      ].filter((item) => {
        return !!item;
      }),
    },
  ];

  return <HorizontalLayout items={items}>{children}</HorizontalLayout>;
});
