import Avatar from "@mui/material/Avatar";
import { Button, Typography } from "@mui/material";
import type { FC } from "react";
import { usePopover } from "src/hooks/use-popover";

import { AccountPopover } from "./account-popover";
import { useAuth } from "src/hooks/use-auth";
import { pictureUrl } from "@/utils/auth";

export const AccountButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>();
  const auth = useAuth();
  const picture = pictureUrl(auth.user);
  const tenantName = auth.user?.tenant.name;

  return (
    <>
      <Button onClick={popover.handleOpen} ref={popover.anchorRef}>
        {tenantName && (
          <Typography
            sx={{ mr: 2 }}
            variant="body2"
            color={"var(--mui-palette-neutral-100)"}
          >
            {tenantName}
          </Typography>
        )}
        <Avatar src={picture}></Avatar>
      </Button>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
