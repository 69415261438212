"use client";

import * as React from "react";
import { CourierProvider } from "@trycourier/react-provider";
import { useAuth } from "@/hooks/use-auth";
import * as config from "../../config";

export interface NotificationProviderProps {
  children: React.ReactNode;
}

export function NotificationProvider({
  children,
}: NotificationProviderProps): React.JSX.Element {
  const auth = useAuth();

  return (
    <CourierProvider
      clientKey={config.COURIER_CLIENT_KEY}
      userId={auth.user?.user.id}
    >
      {children}
    </CourierProvider>
  );
}
