import { createContext } from "react";
import type { RouterOutputs } from "src/utils/trpc";

export interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user?: RouterOutputs["auth"]["me"];
}

export const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: undefined,
};

export interface AuthContextType extends State {
  signIn: () => Promise<void | Error>;
  signInCallback: (
    code: string,
  ) => Promise<RouterOutputs["auth"]["callback"] | null>;
  signOut: () => Promise<void>;
  switchTenant: (accessToken: string, refreshToken: string) => Promise<void>;

  token: string | undefined;
  refreshToken: string | undefined;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  signIn: () => Promise.resolve(),
  signInCallback: () => Promise.resolve(null),
  signOut: () => Promise.resolve(),
  switchTenant: () => Promise.resolve(),
  token: undefined,
  refreshToken: undefined,
});
